import { contactData } from "../../assets/data";
import Separator from "../separator/separator";

const ContactMe = () => {
  return (
    <div id="contact" className="slide slide---md animate__animated">
      <Separator title="Say hello!" direction="center" />
      <div className="contact__container">
        <div className="contact__container__card">
          <span>{contactData.text}</span>
        </div>
        <a href="mailto:tmcrs123@gmail.com">
          <button className="contact__container__button">Contact me</button>
        </a>
      </div>
      
    </div>
  );
};

export default ContactMe;

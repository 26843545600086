import GalleryItem from "../gallery-item/gallery-item";
import { galleryData, smallProjectsData } from "../../assets/data";
import Separator from "../separator/separator";
import cloud_atlas_gif from "../../assets/cloud-atlas.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Gallery = () => {
  const [projectData, setProjectData] = useState(smallProjectsData.slice(0, 6));

  function hasMore() {
    return smallProjectsData.length >= projectData.length + 3;
  }

  function loadMore() {
    if (!hasMore()) return;
    setProjectData([
      ...projectData,
      ...smallProjectsData.slice(projectData.length, projectData.length + 3),
    ]);
  }

  return (
    <div id="gallery" className="slide slide--lg animate__animated">
      <Separator title="gallery" direction="right" />
      <div className="gallery__container">
        <GalleryItem
          direction="right"
          projectData={galleryData[0]}
          imageSource={cloud_atlas_gif}
          imageAlt="Cloud Atlas"
        />
        {/* <GalleryItem direction="right" projectData={galleryData[1]} imageSource={portfolio}/> */}
      </div>
      <div className="gallery__small-projects-title">
        <h3>Small projects</h3>
        <p>Tiny apps that focus on something specific I wanted to learn</p>
      </div>
      <div className="gallery__small-projects">
        {projectData.map((project) => {
          return (
            <div
              key={project.title}
              className="card gallery__small-projects__card animate__animated animate__fadeIn"
            >
              <a
                className="btn--icon gallery__small-projects__card__icon"
                title={project.title}
                href={project.url}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                ></FontAwesomeIcon>
              </a>
              <h4>{project.title}</h4>
              <p className="card-body">{project.description}</p>
            </div>
          );
        })}
      </div>
      <button
        className={`${hasMore() ? "btn--centered" : "btn--hidden"}`}
        onClick={loadMore}
      >
        More
      </button>
    </div>
  );
};

export default Gallery;

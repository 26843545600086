export const landingData = {
  name: "Tiago Rodrigues"
};

export const aboutData = {
  about1: "Hello! I'm <span class=\"text--accent\">Tiago</span> and I like solving problems that involve code.",
  about2: "I haven't always been a software developer. I have a master's degree in <span class=\"text--accent\">finance</span> and I worked in the investment banking industry for a few years. In 2016, I realised finance wasn't really something I enjoyed doing so I decided to embrace software development as a new career path.",
  about3: "Today, I'm a developer who particularly likes working on the <span class=\"text--accent\">frontend</span> but I also love writing <span class=\"text--accent\">backend</span> code and thinking about how to architect backend infrastructure. Occasionally, I dabble in <span class=\"text--accent\">UI</span> design and <span class=\"text--accent\">UX</span> too.",
  about4: "Here a few technologies I've been working with lately:",
  technologies: ["Angular", "C# / .NET Core", "Typescript", "AWS", "NodeJs", "Postgres"],
};

export const workData = [
  {
    index: 4,
    endDate: "Today",
    startDate: "Aug 2021",
    title: "Gap Year",
    companyName: "Planet Earth",
    descriptions: [
      "Took a year off to gain AWS expertise and travel.",
      "Earned the AWS Certified Developer certification while exploring 13 countries, including the UAE, Japan, Vietnam, Australia, and Argentina.",
    ],
  },
  {
    index: 3,
    endDate: "Jan 2024",
    startDate: "Aug 2021",
    title: "Application Developer",
    companyName: "Unilink UK",
    url: "https://www.unilink.com/",
    descriptions: [
      "Developer and maintainer of Unilink's internal custom Angular component library. Worked on components such as date-pickers, wizards or expanding trees.",
      "Implemented a digital signature capturing module, integrating an external signature pad hardware.",
      "Created an official document generation module that automatically resolved client details, collected user input, and generated PDF documents for preview or download.",
      "Conducted demos of new features to Norwegian clients and internal stakeholders.",
      "Technologies used: .NET, Angular, Jest, Docker, SQL Server",
    ],
  },
  {
    index: 2,
    endDate: "Aug 2021",
    startDate: "Aug 2018",
    title: "Full-Stack Developer",
    companyName: "ALD Automotive UK",
    url: "https://www.aldautomotive.co.uk/",
    descriptions: [
      "Developed and maintained ALD's microservices infrastructure and frontend applications.",
      "Built a full-stack special offers module for selected B2B customers, which included: A customer-facing Angular frontend; A .NET Core backend implementing the CQRS design pattern; An Angular internal application for managing special offers.",
      "Led end-of-sprint demos, showcasing completed features and enhancements to stakeholders.",
      "Technologies used: .NET, Angular, RabbitMQ, Oracle SQL and Azure Dev Ops",
    ],
  },
  {
    index: 1,
    endDate: "Dec 2017",
    startDate: "Apr 2017",
    title: ".NET Consultant",
    companyName: "CGI Portugal",
    url: "https://www.cgi.com/portugal/pt-pt",
    descriptions: [
      "Developed and maintained an existing .NET application for internal employee management.",
      "Created three new modules: timesheet management, feedback management, and employee self-assessment",
      "Single - handedly reduced the front-end tech debt backlog for this project by approximately 30%.",
      "Technologies used: .NET, ASP.NET MVC, SQL, Razor, JQuery and Bootstrap.",
    ],
  },
  {
    index: 0,
    endDate: "Oct 2016",
    startDate: "Mar 2015",
    title: "Financial/Marketing Analyist",
    companyName: "Santander, BCP and Uniplaces (Startup)",
    descriptions: [
      "Financial Analyist for 2 of the major portuguese investment banks and Marketing Analyist for a startup.",
      "Responsible for building models for portfolio/marketing analysis.",
      "Main tools: Excel and SQL. I also did a bit of work using Google Adwords and Facebook Ads.",
    ],
  },
];

export const galleryData = [
  {
    name: "Cloud Atlas",
    description:
      "In 2024, I had the chance to finally go on a year-long trip around the world with my partner. When I got back, I wanted a way to ease back into coding while also documenting this adventure and any future travels I might make. That’s what led me to create this app.",
    technologies: [
      "AWS (ECS, Lambda, Cloudfront, Cognito, Dynamo DB, etc...)",
      "Angular",
      "Fastify/ NodeJs",
      "Typescript",
    ]
  },
  {
    name: "This website - My Portfolio",
    description:
      "LinkedIn is alright but every page looks the same there. So I created my own portfolio. It was also a pretext to brush up on my React skills",
    technologies: ["AWS", "React", "Javascript", "CSS"],
  },
  {
    name: "Apartment 65 - Hotel Reservations",
    description: "A hotel management app - Coming soon",
    technologies: ["AWS", "React", "Javascript", "CSS"],
  },
];

export const smallProjectsData = [
  {
    title: "Angular Study Notes",
    description: "A bare-bones website to test and explain Angular concepts ",
    url: "http://angular-notes-ui.s3-website-us-east-1.amazonaws.com/"
  },
]

export const contactData = {
  text: "I'm currently looking for a new role so if you’d like to reach out, please click the button below. I’ll get back to you as soon as possible.",
};
